<template>
  <div class="wrapper">
    <router-view />
  </div>
</template>

<script>
  export default {
    name: 'ExceptionLayout',
    data() {
      return {
      };
    },
    methods: {
    },
  }
</script>

<style scoped>
  .wrapper {
    min-height: 100%;
    text-align: center;
    padding: 10%;
  }
</style>